


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31910.65829252558!2d36.79890565404656!3d-1.2738430176967313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f17fcc28d5001%3A0x5bdbf107809e4806!2sLemur%20House%20of%20Travel!5e0!3m2!1sen!2ske!4v1700907386956!5m2!1sen!2ske"></iframe>
            </div>
        </div>
    );
}
 
export default GoogleMap;