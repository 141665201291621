import { Component, Fragment } from "react";
import FooterTwo from "../component/layout/footer";
import Header from "../component/layout/header";
import AchievementThree from "../component/section/achievement-3";
import BannerThree from "../component/section/banner-3";
// import BlogTwo from "../component/section/blog";
// import Clients from "../component/section/clients";
import CourseThree from "../component/section/course";
// import Event from "../component/section/event";
import Feature from "../component/section/feature";
// import NewsLetter from "../component/section/newsletter";
// import Workshop from "../component/section/workshop";
// import WorkshopTwo from "../component/section/workshop";


const Teacher = () => {
    return (
        <Fragment>
            <Header />
            <BannerThree />
            {/* <Feature /> */}
            {/* <CourseThree /> */}
            <AchievementThree />
            {/* <Event /> */}
            {/* <Workshop /> */}
            {/* <WorkshopTwo /> */}
            {/* <BlogTwo /> */}
            {/* <Clients /> */}
            {/* <NewsLetter /> */}
            <FooterTwo />
        </Fragment>
    );
}
 
export default Teacher;