


// const subTitle = "Who Are We?";
// const title = "What Is Soma Na Chacho";
// const desc = "Soma na Chacho is a pioneering organization dedicated to transforming the educational landscape in Kenya. The organization's primary mission is to provide high-quality, interactive, and comprehensive online reading materials tailored to the Competency-Based Curriculum (CBC) implemented in Kenyan schools. This comprehensive explanation delves into the core values, offerings, and impact of Soma na Chacho in enhancing education within the Kenyan context."


// const aboutList = [
//     // {
//     //     imgUrl: 'assets/images/about/icon/01.jpg',
//     //     imgAlt: 'about icon rajibraj91 rajibraj',
//     //     title: 'Personalized student accounts for tracking progress.',
//     //     // desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
//     // },
//     // {
//     //     imgUrl: 'assets/images/about/icon/02.jpg',
//     //     imgAlt: 'about icon rajibraj91 rajibraj',
//     //     title: 'User-friendly navigation and search options.',
//     //     // desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
//     // },
//     // {
//     //     imgUrl: 'assets/images/about/icon/03.jpg',
//     //     imgAlt: 'about icon rajibraj91 rajibraj',
//     //     title: 'Compatibility with various devices and internet connections.',
//     //     // desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
//     // },
// ]

// const About = () => {
//     return (
//         <div className="about-section">
//             <div className="container">
//                 <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
//                     <div className="col">
//                         <div className="about-right padding-tb">
//                             <div className="section-header">
//                                 <span className="subtitle">{subTitle}</span>
//                                 <h2 className="title">{title}</h2>
//                                 <p>{desc}</p>

//                                 {/* Button after the description */}
//                                 <button type="button" className="btn btn-primary">
//                                     Read More
//                                 </button>
//                             </div>

//                             <div className="section-wrapper">
//                                 <ul className="lab-ul">
//                                     {aboutList.map((val, i) => (
//                                         <li key={i}>
//                                             <div className="sr-left">
//                                                 <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
//                                             </div>
//                                             <div className="sr-right">
//                                                 <h5>{val.title}</h5>
//                                                 <p>{val.desc}</p>
//                                             </div>
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col">
//                         <div className="about-left">
//                             <div className="about-thumb">
//                                 <img src="assets/images/about/01.png" alt="about" />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default About;

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; // Import your Link component from React Router
// import Header from './Header'; // Import your Header component
// import PageHeader from './PageHeader'; // Imp/Users/mac/Downloads/Ndovubase_projects/edukon/src/component/layout/pageheader.jsxort your PageHeader component
// import Student from './Student'; // Import your Student component
// import Achievement from './Achievement'; // Import your Achievement component
// import Footer from './Footer'; // Import your Footer component

const About = () => {
    // Data for the About component
    const subTitle = "Who Are We?";
    const title = "What Is Soma Na Chacho";
    const desc = "Soma na Chacho is a pioneering organization dedicated to transforming the educational landscape in Kenya. The organization's primary mission is to provide high-quality, interactive, and comprehensive online reading materials tailored to the Competency-Based Curriculum (CBC) implemented in Kenyan schools. This comprehensive explanation delves into the core values, offerings, and impact of Soma na Chacho in enhancing education within the Kenyan context."
    

    const aboutList = [
    // {
    //     imgUrl: 'assets/images/about/icon/01.jpg',
    //     imgAlt: 'about icon rajibraj91 rajibraj',
    //     title: 'Personalized student accounts for tracking progress.',
    //     // desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    // },
    // {
    //     imgUrl: 'assets/images/about/icon/02.jpg',
    //     imgAlt: 'about icon rajibraj91 rajibraj',
    //     title: 'User-friendly navigation and search options.',
    //     // desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    // },
    // {
    //     imgUrl: 'assets/images/about/icon/03.jpg',
    //     imgAlt: 'about icon rajibraj91 rajibraj',
    //     title: 'Compatibility with various devices and internet connections.',
    //     // desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    // },
]

    // Data for the second part of the component
    let categoryItemList = [
    {
        imgUrl: 'assets/images/category/icon/15.jpg',
        imgAlt: 'category',
        title: 'Curriculum-Aligned Reading Materials',
        desc: 'Soma na Chachos extensive library encompasses reading materials that seamlessly align with the CBC curriculum. This includes e-books, audio materials, and multimedia content.',
    },
    {
        imgUrl: 'assets/images/category/icon/16.jpg',
        imgAlt: 'category',
        title: 'Interactive Learning Tools',
        desc: 'The platform offers interactive quizzes, assignments, and progress tracking features. Learners can explore content in a fun and engaging manner, thereby improving retention and understanding.',
    },
    {
        imgUrl: 'assets/images/category/icon/17.jpg',
        imgAlt: 'category',
        title: 'Teacher Support',
        desc: 'Soma na Chacho assists educators by providing resources, lesson plans, and assessment tools. Teachers can utilize these materials to enhance their teaching strategies.',
        },
    
]
    const btnText = 'Learn More';
    
    return (
        <Fragment>
            {/* <Header / */}
            {/* <PageHeader title={'All Best Instructors'} curPage={'instructor'} />  */}
            
            {/* Second Part of the Component */}
            

            {/* About Component */}
            <div className="about-section">
                <div className="container">
                    <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
                        <div className="col">
                            <div className="about-right padding-tb">
                                <div className="section-header">
                                    <span className="subtitle">{subTitle}</span>
                                    <h2 className="title">{title}</h2>
                                    <p>{desc}</p>

                                    {/* Button after the description */}
                                    {/* <button type="button" className="btn btn-primary">
                                        Click Me
                                    </button> */}
                                </div>
                                <div className="section-wrapper">
                                    <ul className="lab-ul">
                                        {aboutList.map((val, i) => (
                                            <li key={i}>
                                                <div className="sr-left">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="sr-right">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src="assets/images/about/01.png" alt="about" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="category-section padding-tb section-bg style-3">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
                            {categoryItemList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="category-item text-center">
                                        <div className="category-inner">
                                            <div className="category-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="category-content">
                                                <Link to="/signup"><h4>{val.title}</h4></Link>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="text-center mt-5">
                            <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Other Components */}
            {/* <Student />
            <Achievement />
            <Footer /> */}
        </Fragment>
    );
}

export default About;
