


const subTitle = "Online education";
const title = <h2 className="title"><span className="d-lg-block">Revolutionizing Education </span>  <span className="d-lg-block"></span></h2>;
const desc = "With Online Reading Materials For The Kenyan CBC Curriculum";


// const catagoryList = [
//     {
//         name: 'English',
//         link: '#',
//     },
//     {
//         name: ' Science',
//         link: '#',
//     },
//     {
//         name: 'Life Skills and Values Education',
//         link: '#',
//     },
//     {
//         name: 'Social Studies',
//         link: '#',
//     },
  
//     {
//         name: 'Mathemetics',
//         link: '#'
//     },

       
// ]


const shapeList = [
    {
        name: 'CBC Kenya content',
        link: '#',
        className: 'ccl-shape shape-1',
    },
    {
        name: 'Courses',
        link: '#',
        className: 'ccl-shape shape-2',
    },
    {
        name: '89% Successful Students',
        link: '#',
        className: 'ccl-shape shape-3',
    },
    {
        name: 'Learners',
        link: '#',
        className: 'ccl-shape shape-4',
    },
    {
        // name: '36+ ',
        // link: '#',
        // className: 'ccl-shape shape-5',
    },
]

const Banner = () => {
    return (
        <section className="banner-section">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-xxl-5 col-xl-6 col-lg-10">
                            <div className="banner-content">
                                <h6 className="subtitle text-uppercase fw-medium">{subTitle}</h6>
                                {title}
                                <p className="desc">{desc}</p>
                                <form action="/">
                                    <div className="banner-icon">
                                        <i className="icofont-search"></i>
                                    </div>
                                    <input type="text" placeholder="Keywords of your course" />
                                    <button type="submit">Search Course</button>
                                </form>
                                <div className="banner-catagory d-flex flex-wrap">
                                    {/* <p>Most Popular : </p>
                                    <ul className="lab-ul d-flex flex-wrap">
                                        {catagoryList.map((val, i) => (
                                            <li key={i}><a href={val.link}>{val.name}</a></li>
                                        ))}
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6">
                            <div className="banner-thumb">
                                <img src="assets/images/banner/1.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="all-shapes"></div>
            <div className="cbs-content-list d-none">
                <ul className="lab-ul">
                    {shapeList.map((val, i) => (
                        <li className={val.className} key={i}><a href={val.link}>{val.name}</a></li>
                    ))}
                </ul>
            </div>
        </section>
    );
}
 
export default Banner;