import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import About from "../component/section/about";
import Achievement from "../component/section/achievement.jsx";
import Banner from "../component/section/banner";
// import Blog from "../component/section/blog";
import Category from "../component/section/category";
// import AboutChacho from "../component/section/about-chacho";
// import Course from "../component/section/course";
// import Instructor from "../component/section/instructor";
import Sponsor from "../component/section/sponsor";
// import Student from "../component/section/student";
import Event from "../component/section/event";



const Home = () => {
    return (
        <Fragment>
            <Header />
            <Banner />
            <About />
            {/* <AboutChacho/> */}
            <Category />
            {/* <Course /> */}
            
            {/* <Instructor />
            <Student /> */}
            {/* <Blog /> */}
            <Achievement /> 
            <Event/>
           <Sponsor />
            
            <Footer />
        </Fragment>
    );
}
 
export default Home;