import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const initialShopCartList = [
  {
    grade: 1,
    imgUrl: 'assets/images/shop/science.png',
    imgAlt: 'science',
    title: 'Mathematics',
    price: 10,
    quantity: 0,
  },
  {
    grade: 1,
    imgUrl: 'assets/images/shop/english.png',
    imgAlt: 'Product Thumb',
    title: 'English',
    price: 10,
    quantity: 0,
  },
  {
    grade: 1,
    imgUrl: 'assets/images/shop/life-skills.png',
    imgAlt: 'Product Thumb',
    title: 'Life Skills',
    price: 10,
    quantity: 0,
  },
  {
    grade: 1,
    imgUrl: 'assets/images/shop/science.png',
    imgAlt: 'Product Thumb',
    title: 'Science',
    price: 10,
    quantity: 0,
  },
  {
    grade: 1,
    imgUrl: 'assets/images/shop/social-studies.png',
    imgAlt: 'Product Thumb',
    title: 'Social Studies',
    price: 10,
    quantity: 0,
  },
  // Add more items for Grade 1...

  {
    grade: 2,
    imgUrl: 'assets/images/shop/science.png',
    imgAlt: 'science',
    title: 'Mathematics',
    price: 10,
    quantity: 0,
  },
  {
    grade: 2,
    imgUrl: 'assets/images/shop/english.png',
    imgAlt: 'Product Thumb',
    title: 'English',
    price: 10,
    quantity: 0,
  },
  // Add more items for Grade 2...

  {
    grade: 3,
    imgUrl: 'assets/images/shop/science.png',
    imgAlt: 'science',
    title: 'Mathematics',
    price: 10,
    quantity: 0,
  },
  {
    grade: 3,
    imgUrl: 'assets/images/shop/english.png',
    imgAlt: 'Product Thumb',
    title: 'English',
    price: 10,
    quantity: 0,
  },
  // Add more items for Grade 3...
];

const CartPage = () => {
  const [cartItems, setCartItems] = useState(initialShopCartList);

  const handleQuantityChange = (index, newQuantity) => {
    const updatedCart = [...cartItems];
    updatedCart[index].quantity = newQuantity;
    setCartItems(updatedCart);
  };

  // const handleRemoveItem = (index) => {
  //   const updatedCart = cartItems.map((item, i) =>
  //     i === index ? { ...item, quantity: 0 } : item
  //   );
  //   setCartItems(updatedCart);
  // };
  
  const calculateTotal = () => {
    const totalPrice = cartItems.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    return totalPrice;
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={'Course content'} curPage={'Course Content Page'} />
      <div className="shop-cart padding-tb">
        <div className="container">
          <div className="section-wrapper">
            {[1, 2, 3].map((grade) => (
              <div className="cart-top" key={grade}>
                <h2>Grade {grade}</h2>
                <table>
                  <thead>
                    <tr>
                      <th className="cat-product">Product</th>
                      <th className="cat-price">Price</th>
                      <th className="cat-quantity">Quantity</th>
                      <th className="cat-toprice">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems
                      .filter((item) => item.grade === grade)
                      .map((val, i) => (
                        <tr key={i}>
                          <td className="product-item cat-product">
                            <div className="p-thumb">
                              <Link to="/">
                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                              </Link>
                            </div>
                            <div className="p-content">
                              <Link to="/">{val.title}</Link>
                            </div>
                          </td>
                          <td className="cat-price">{val.price}</td>
                          <td className="cat-quantity">
                            <div className="cart-plus-minus">
                              <div
                                className="dec qtybutton"
                                onClick={() => handleQuantityChange(i, val.quantity - 1)}
                              >
                                -
                              </div>
                              <input
                                className="cart-plus-minus-box"
                                type="text"
                                name="qtybutton"
                                value={val.quantity}
                                readOnly
                              />
                              <div
                                className="inc qtybutton"
                                onClick={() => handleQuantityChange(i, val.quantity + 1)}
                              >
                                +
                              </div>
                            </div>
                          </td>
                          <td className="cat-toprice">{val.price * val.quantity}</td>
                          {/* <td className="cat-edit">
                            <a href="#" onClick={() => handleRemoveItem(i)}>
                              <img src={`${val.delImgUrl}`} alt={`${val.delImgAlt}`} />
                            </a>
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ))}
            <div className="cart-bottom">
              <div className="cart-checkout-box">
                <form className="coupon" action="/">
                  <input type="text" name="coupon" placeholder="Coupon Code..." className="cart-page-input-text" />
                  <input type="submit" value="Apply Coupon" />
                </form>
                <form className="cart-checkout" action="/">
                  <input type="submit" value="Update Cart" />
                  <input type="submit" value="Proceed to Checkout" />
                </form>
              </div> 
              <div className="shiping-box">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="cart-overview">
                      <h3>Cart Totals</h3>
                      <ul className="lab-ul">
                        <li>
                          <span className="pull-left">Cart Subtotal</span>
                          <p className="pull-right">$ 0.00</p>
                        </li>
                        <li>
                          <span className="pull-left">Order Total</span>
                          <p className="pull-right">$ {calculateTotal()}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default CartPage;
