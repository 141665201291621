
import { Link } from "react-router-dom";

const subTitle = "Popular Courses";
const title = "Popular Courses For Learn";
const btnText = "Browse All Categories";


const categoryList = [
    {
        imgUrl: 'assets/images/category/icon/english.png',
        imgAlt: 'Language Arts and Literacy',
        title: 'Language Arts ',
        // count: '24 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/maths.png',
        imgAlt: 'Ki',
        title: 'Mathematics',
        // count: '04 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/science.png',
        imgAlt: 'Mathematics',
        title: 'Science',
        // count: '27 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/swahili.jpg',
        imgAlt: 'Science',
        title: 'Swahili',
        // count: '28 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/social-studies.png',
        imgAlt: 'Social Studies',
        title: 'Social Studies',
        // count: '78 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/lifeskills.png',
        imgAlt: 'Life Skills and Values Education',
        title: 'Life Skills ',
        // count: '38 Course',
    },
]


const Category = () => {
    return (
        <div className="category-section padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-2 justify-content-center row-cols-xl-6 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                        </div>
                                        <div className="category-content">
                                            <Link to="/cart-page"><h6>{val.title}</h6></Link>
                                            <span>{val.count}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        ))}
                    </div>
                    <div className="text-center mt-5">
                        <Link to="/cart-page" className="lab-btn"><span>{btnText}</span></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Category;